import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import "./home.css";
import swal from "sweetalert";

function Home() {
    const navigate = useNavigate();
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    //const [isShow, setShow] = useState(false);
    const [profile, setProfile] = useState(null);
    const [setting, setSetting] = useState(null);
    const [notify, setNotify] = useState();
    const [isLoading, setLoading] = useState(false);
    const [fishing, setFishing] = useState(null);
    const [slots, setSlots] = useState(null);
    useEffect(() => {
        axios
            .get(url, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            setNotify(res.data.data[0]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            setSetting(res.data.data[0]);
        });		
    }, []);
    useEffect(() => {
        //axios.post(`${process.env.REACT_APP_API_URL}/casino/history`, {});
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {})
    }, [profile]);
    const [activeOption, setActiveOption] = useState("2");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const kmNap = () => {
        swal("Khuyến mãi nạp hàng ngày", `Hôm nay bạn chưa nạp tiền. Hãy nạp tiền để được nhận khuyến mãi nạp đầu ngày trị giá ${setting?.kmnapdau}% số tiền nạp.`, "info");
    };
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    if (url.length != 42) return;
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    window.location.href = res.data.data.game_url;
                    setLoading(false);
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
                <div className="main">
                    <div className="header">
                        <div className="header-top">
                            <div className="logo">
                                <Link to="/">
                                    <img alt="" src="/upload/home.png" />
                                </Link>
                            </div>
                            <div className="header-right">
                                {profile ? (
                                    <div style={{ display: "flex", float: "right" }}>
                                        {profile?.napdau == false && (
                                            <a onClick={() => kmNap()} className="btn-login bounce" style={{ fontSize: "11px", cursor: "pointer", padding: "5px 8px" }}>
                                                Nhận thưởng
                                            </a>
                                        )}
                                        <span style={{ marginRight: "0.111rem" }}>
                                            Số dư: <b>{Math.floor(profile.money).toLocaleString("vi-VN")}</b>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="button-top">
                                        <Link to="/login" className="btn-login">
                                            Đăng nhập
                                        </Link>
                                        <Link to="/register" className="btn-register">
                                            Đăng ký
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box-image">
                        <Swiper {...swiperParams}>
                            <SwiperSlide>
                                <img alt="" src="/upload/i1.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="" src="/upload/xo-so.jpg" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img alt="" src="/upload/i2.jpg" />
                            </SwiperSlide>
                        </Swiper>
                        {notify ? (
                            <>
                                {notify.isShow === true && notify.title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <CampaignIcon sx={{ fontSize: "22px" }} />
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify.content.length / 8 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify.content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div className="lottery-home">
                        <div className="lottery-menu">
                            <div className="lottery-menu-top">
                                <div className="lottery-item" onClick={() => handleOptionClick("1")}>
                                    <img alt="" src={require("../../img/hotLottery.0a733060.png")} />
                                    <span>Xổ số</span>
                                </div>
                                <div className="lottery-item" onClick={() => handleOptionClick("2")}>
                                    <img alt="" src={require("../../img/all.a4d78610.png")} />
                                    <span>Tất cả</span>
                                </div>
                                <div className="lottery-item" onClick={() => launchGame({ product_type: "112", game_code: "SEX002" })}>
                                    <img alt="" src={require("../../img/lobby.e990b38c.png")} />
                                    <span>Live Casino</span>
                                </div>
                            </div>
                            <div className="lottery-menu-mid">
                                <Link className="lottery-item" to="/taixiu5">
                                    <img alt="" src={require("../../img/SICBO.30ef5ab9.png")} />
                                    <span>Tài xỉu</span>
                                </Link>
                                <div className="lottery-item" onClick={() => handleOptionClick("4")}>
                                    <img alt="" src={require("../../img/quayhu.png")} style={{ padding: "0.25rem 0" }} />
                                    <span>Quay hũ</span>
                                </div>
                                <div className="lottery-item" onClick={() => handleOptionClick("3")}>
                                    <img alt="" src={require("../../img/banca.png")} style={{ padding: "0.15rem 0.42rem 0", height: "95px", maxHeight: "80%" }} />
                                    <span>Bắn cá</span>
                                </div>
                                <div
                                    className="lottery-item"
                                    onClick={() => (setting?.greedy?.open == 1 ? navigate("/greedy") : swal("Thông báo", "Trò chơi đang bảo trì", "info"))}>
                                    <img
                                        alt=""
                                        src={require("../../img/award_bigo.png")}
                                        style={{ padding: "0.15rem 0.42rem 0.25rem 0.42rem", height: "105px", maxHeight: "80%" }}
                                    />
                                    <span>Greedy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-game">
                        <div className="list-game">
                            {activeOption === "1" && (
                                <>
                                    <div className="box-game">
                                        <Link to="/xoso3p">
                                            <img alt="" src={require("../../img/lottery-81925723.png")} style={{ margin: "8px 5px 0 0" }} />
                                            <h3>XỔ SỐ NHANH</h3>
                                            <div className="box-game-text">
                                                <div>Dự đoán</div>
                                                <div>Dự đoán xổ số để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmb">
                                        <Link to="/xsmb">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Bắc</div>
                                                <div>Dự đoán kết quả xổ số miền Bắc để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmt">
                                        <Link to="/xsmt">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Trung</div>
                                                <div>Dự đoán kết quả xổ số miền Trung để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmn">
                                        <Link to="/xsmn">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Nam</div>
                                                <div>Dự đoán kết quả xổ số miền Nam để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            )}
                            {activeOption === "2" && (
                                <>
                                    <div className="box-game">
                                        <Link to="/xocdia5">
                                            <img alt="" src={require("../../img/xocdia.png")} />
                                            <h3>SẢNH MD5</h3>
                                            <div className="box-game-text">
                                                <div>Đoán số</div>
                                                <div>Lớn/Nhỏ/Lẻ/Chẵn để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game">
                                        <Link to="/taixiu5">
                                            <img alt="" src={require("../../img/k3-3fb4362a.png")} style={{ margin: "0 5px 0 0" }} />
                                            <h3>LỘC PHÁT MD3</h3>
                                            <div className="box-game-text">
                                                <div>Dự đoán</div>
                                                <div>Dự đoán Xúc sắc để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game">
                                        <Link to="/xoso5p">
                                            <img alt="" src={require("../../img/lottery-81925723.png")} style={{ margin: "8px 5px 0 0" }} />
                                            <h3>XỔ SỐ NHANH</h3>
                                            <div className="box-game-text">
                                                <div>Dự đoán</div>
                                                <div>Dự đoán xổ số để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmb">
                                        <Link to="/xsmb">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Bắc</div>
                                                <div>Dự đoán kết quả xổ số miền Bắc để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmt">
                                        <Link to="/xsmt">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Trung</div>
                                                <div>Dự đoán kết quả xổ số miền Trung để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="box-game op xsmn">
                                        <Link to="/xsmn">
                                            <img alt="" src={require("../../img/logo-Vlottery.webp")} style={{ marginLeft: "-15px" }} />
                                            <h3>XỔ SỐ TRUYỀN THỐNG</h3>
                                            <div className="box-game-text">
                                                <div>Xổ số miền Nam</div>
                                                <div>Dự đoán kết quả xổ số miền Nam để giành chiến thắng</div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            )}
                            {activeOption === "3" && (
                                <>
                                    <div className="gameapi-list">
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0136" })}>
                                            <img alt="Ocean King Jackpot" src="https://images.21947392.com/TCG_GAME_ICONS/JL/JL0136.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0111" })}>
                                            <img alt="Dinosaur Tycoon II" src="https://images.21947392.com/TCG_GAME_ICONS/JL/JL0111.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0050" })}>
                                            <img alt="All-star Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/119.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0009" })}>
                                            <img alt="Happy Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/82.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0008" })}>
                                            <img alt="Boom Legend" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/71.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0007" })}>
                                            <img alt="Mega Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/74.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0006" })}>
                                            <img alt="Dragon Fortune" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/60.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0005" })}>
                                            <img alt="Jackpot Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/32.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0004" })}>
                                            <img alt="Dinosaur Tycoon" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/42.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0003" })}>
                                            <img alt="Bombing Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/20.png" />
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "140", game_code: "JL0002" })}>
                                            <img alt="Royal Fishing" src="https://ossimg.ngrbet.com/veso/gamelogo/JILI/1.png" />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div className="gameapi-list">
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB682" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB682.png" alt="Beauty Party" />
                                            <div className="gameapi-name">Dạ hội AV</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB689" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB689.png" alt="Super Bingo" />
                                            <div className="gameapi-name">Lô tô siêu Bingo</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB688" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB688.png" alt="Bingo Money Tree" />
                                            <div className="gameapi-name">Cây tiền Bingo</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB686" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB686.png" alt="Firecrackers" />
                                            <div className="gameapi-name">Pháo nổ kiếm tiền</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB662" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB662.png" alt="Too Rich" />
                                            <div className="gameapi-name">Thần tài đến</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB660" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB660.png" alt="Merry Christmas" />
                                            <div className="gameapi-name">Giáng sinh vui vẻ</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB440" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB440.png" alt="Happy New Year" />
                                            <div className="gameapi-name">Chúc mừng năm mới</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB441" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB441.png" alt="Lucky Ox" />
                                            <div className="gameapi-name">Trâu vàng</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB619" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB619.png" alt="FRUIT NINJA" />
                                            <div className="gameapi-name">Cắt hoa quả</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB067" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB067.png" alt="Fruit" />
                                            <div className="gameapi-name">Xèng 777</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB066" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB066.png" alt="Lucky Number" />
                                            <div className="gameapi-name">Vòng quay may mắn</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB086" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB086.png" alt="European Roulette" />
                                            <div className="gameapi-name">Roulette</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB114" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB114.png" alt="LEGEND OF GEMS" />
                                            <div className="gameapi-name">Xếp kim cương</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB668" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB668.png" alt="Gamblers" />
                                            <div className="gameapi-name">Thần bài</div>
                                        </div>
                                        <div className="gameapi-item" onClick={() => launchGame({ product_type: "79", game_code: "BBB450" })}>
                                            <img src="https://images.21947392.com/TCG_GAME_ICONS/BB/BBB450.png" alt="Street Food" />
                                            <div className="gameapi-name">Ẩm thực đường phố</div>
                                        </div>
                                    </div>
                                    <Link className="view-more" to="/slots">
                                        Xem thêm
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
        </>
    );
}
export default Home;
